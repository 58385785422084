.info-container {
    position: relative;
    padding-right: 20px; /* Space for the icon at the end */
  }
  
  .info-icon {
    color: #6C757D !important;
    position: absolute;
    right: 0; /* Align the icon to the far right */
    top: 50%; /* Center the icon vertically */
    transform: translateY(-50%); /* Adjust vertical centering */
    cursor: pointer;
  }
  