@layer base {
  @font-face {
    font-family: INVENTION_BOLD !important;
    src: url(../public/font/Invention_W_Bd.woff);
  }
}
/* the .no-border class is specifically for hiding the borders created 
by react-jsonschema-form library */
.no-border fieldset {
  border: none !important;
}



